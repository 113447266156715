import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Partners from "../components/partners/partners"

const IndexPage = () => (
  <Layout>
    <SEO title="Page d'accueil" />

    <section className="container small">
      <h2 className="floating"><span>La formation</span></h2>
      <p>De septembre à novembre 2020, nous vous proposons une formation innovante dédiée aux demandeurs d’emploi motivés d’apprendre les métiers de designer et développeur web front-end. Cette formation se déroulera à l'Eurometropolitan e-campus de Tournai et vous proposera de collaborer en équipe sur des projets réels soumis par des entreprises et associations de la région.</p>
      <p>Découvrez <Link to="/formation">notre offre de formation</Link> pour en savoir plus.</p>
    </section>

    <section className="container small">
      <h2 className="floating"><span>Qu'est-ce ?</span></h2>
      <p>TechYourChance est une école à impact social qui propose des formations aux métiers du numérique, 100 % gratuites pour les jeunes, les demandeurs d’emploi et les personnes en reconversion.</p>
      <p>Notre offre de formation se distingue par une approche collaborative en mode projet. L'idée n'est donc pas de faire de vous des "têtes bien pleines" mais de vous aider à appréhender les contraintes que rencontrent les agences numériques au jour le jour.</p>
      <p>Découvrez <Link to="/projet">notre projet</Link> pour en savoir plus.</p>
    </section>

    <Partners />
  </Layout>
)

export default IndexPage
